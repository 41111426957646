global.reportHandler = {}

reportHandler.showCurrentPaginationCircle = function(index) {
  $('.pagination-circles .pagination-circle').removeClass('active');
  $('.pagination-circles .pagination-circle:eq(' + index + ')').addClass('active');
};

reportHandler.showReportSection = function(reportClass) {
  $(reportClass + ' .report-mobile-trait-images').on('click', '.img', function(){
    $(this).siblings('.img').removeClass('active');
    $(this).addClass('active');
    $(reportClass).find(".report-section-header, .txt").removeClass('active');

    var sectionId = $(this).data('section-id');
    $('#' + sectionId).find(".report-section-header, .txt").addClass('active');

    var currentIndex = $(this).index(reportClass + ' .img');
    reportHandler.showCurrentPaginationCircle(currentIndex);
  });

  if ($(window).width() <= 1024) {
    $(reportClass + ' .img:eq(0)').trigger('click');
  }
};

setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  // Personality Feedback
  $('.report-content').on('click', '.trait-bar', function(){
    $(this).closest('.traits-chart').find('.trait-bar').removeClass('active');
    $(this).addClass('active');

    var currentTarget = $(this).find('.trait-label').data("target-block");
    $('.report-table .report-section').removeClass('active');
    $(".report-table .report-section#" + currentTarget).addClass('active');

    var currentIndex = $(this).index('.trait-bar');
    reportHandler.showCurrentPaginationCircle(currentIndex);
  });

  $('.report-content .trait-bar:eq(0)').trigger('click');

  // Career Guidance
  reportHandler.showReportSection('.career-guidence-report');

  // Interview coaching
  reportHandler.showReportSection('.kra-report');

  var upgradePopupContent = $('#assessment-upgrade-facebox');

  if (upgradePopupContent.length) {
    $.facebox(upgradePopupContent.html(), 'hide-close', { overlay: false, close: false });
  }
});
