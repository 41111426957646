global.mdPaginationHandler = {};

mdPaginationHandler.changeSection = function(direction) {
  var currentTarget = $('.pagination-circle.active');

  if(currentTarget[direction]('.pagination-circle').length)
    currentTarget[direction]('.pagination-circle').trigger('click');
  else
    $('.pagination-circle:last').trigger('click');
};

setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  $('.md-js-pagination .pagination-circles').on('click', '.pagination-circle',function () {
    var parent = $(this).closest('.pagination-circles'),
        targetSections = $(parent.attr('data-targer-sections'));

    parent.find('.pagination-circle').removeClass('active');
    $(this).addClass('active');

    var currentIndex = $(this).index('.pagination-circle');

    if (targetSections.length) {
      $(targetSections).eq(currentIndex).trigger('click');
    }
  });

  $('.md-js-pagination').on('click','.left-arrow',function(){
    mdPaginationHandler.changeSection('prev');
  });

  $('.md-js-pagination').on('click','.right-arrow',function(){
    mdPaginationHandler.changeSection('next');
  });
});