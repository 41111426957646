global.sidenav = {};

sidenav.open = function () {
  $("#sidenav").addClass("opened");
  $("#overlay").show();
  $("body").addClass("tw-overflow-hidden");
};

sidenav.close = function () {
  $("#sidenav").removeClass("opened");
  $("#overlay").hide();
  $("#sidenav_content").empty();
  $("body").removeClass("tw-overflow-hidden");
};

setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  $('#overlay').on("click", function(){
    sidenav.close();
  });
});
