global.faceboxHelper = {}

faceboxHelper.toggleDependOnOption = function (id, event){
  var facebox =  $('#facebox:visible'),
      value = event.target.value,
      customOption = event.target.dataset.customOption;

  facebox.find(id).val("")
         .toggleClass('hidden', customOption !== value)
         .find("input").val("");
}
