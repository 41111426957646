global.tooltipHandler = {};

tooltipHandler.setTippyTooltips = function () {
  tippy('[data-tippy-content]', { allowHTML: true, touch: true });
};

tooltipHandler.hideTippy = function (selector) {
  var element = document.querySelector(selector);

  if (element && element._tippy) {
    element._tippy.hide();
  }
};

tooltipHandler.setEventListeners = function() {
  tooltipHandler.setTippyTooltips();

  tippy('[data-tippy-dropdown]', {
    content: function(target) {
      return $(target.dataset.tippyDropdown).html();
    },
    allowHTML: true,
    interactive: true,
    trigger: 'click',
    theme: 'dropdown',
    placement: 'bottom-start'
  });

  tippy('[data-editable-ai-document-content]', {
    content: $('#edit-ai-document-form-block').html(),
    onShown: function(instance) {
      var reference = instance.reference,
          text = reference.innerText,
          popper = $(instance.popper);

      popper.find('#ai_document_section_content').val(text);
      popper.find('#ai_document_section_key_path').val(reference.dataset.editableAiDocumentContent);
    },
    onHide: function (instance) {
      $(instance.popper).find('.md-error').text('');
    },
    interactive: true,
    trigger: 'click',
    allowHTML: true,
    placement: 'bottom',
    theme: 'light-border'
  });
};

setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  tooltipHandler.setEventListeners();
});
