global.urlHandler = {
  getParam: function(name){
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search)||[,""])[1].replace(/\+/g, '%20'))||null
  },

  insertParam: function(key, value) {
    key = encodeURI(key); value = encodeURI(value);
    var kvp = document.location.search.substr(1).split('&');
    var i=kvp.length; var x;

    while(i--) {
      x = kvp[i].split('=');
      if (x[0]==key) {
        x[1] = value;
        kvp[i] = x.join('=');
        break;
      }
    }

    if(i<0) {kvp[kvp.length] = [key,value].join('=');}
    //this will reload the page, it's likely better to store this until finished
    document.location.search = kvp.join('&');
  },

  updateQueryParam: function (url, key, value) {
    let urlObj = new URL(url);
    urlObj.searchParams.set(key, value);
    return urlObj.toString();
  },

  updateIframeSrc: function (iframe, paramKey, paramValue) {
    if (iframe.src) {
      iframe.src = this.updateQueryParam(iframe.src, paramKey, paramValue);
    }
  },

  addSortingParams: function(element){
    var current_location = window.location.href,
        location         = window.location.href.match(/^(.*)\?/);

    if ( location ) location = location[1];
    else            location  = current_location;

    var page_audit   = current_location.match(/audit_page=\d+/),
        page         = current_location.match(/page=\d+/),
        access_token = current_location.match(/referral_access_token=\w+/),
        audit_id     = current_location.match(/audit_id=\d+/),
        user_id      = current_location.match(/user_id=\d+/);

    current_location = current_location.replace("audit_page", "");
    location += "?field="+ element.attr("field") + "&direction=" +  element.attr("direction") + "&" + page;

    if (page_audit)   location += "&" + page_audit;
    if (access_token) location += "&" + access_token;
    if (audit_id)     location += "&" + audit_id;
    if (user_id)      location += "&" + user_id;

    window.location.href = location;
  }
}


setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  $(".user_page_audit_result, .search_users, .audits, .referrals").
    find("th span.ordered, th.ordered").on('click', function(){
      urlHandler.addSortingParams($(this));
    });

  $(".search-result").find(".header-cell .ordered").on('click', function(){
      urlHandler.addSortingParams($(this));
    });
});
