if (!window.Turbo) {
  require("@hotwired/turbo-rails")
}

import $ from "jquery";

global.$ = global.jQuery = $;
window.$ = window.jQuery = $;

import tippy from 'tippy.js';
global.tippy = tippy;

require('jquery.browser/dist/jquery.browser.min.js');
require('jquery-ujs/src/rails.js');
require('jquery-ui/jquery-ui.min.js');
require('jquery-ui-touch-punch/jquery.ui.touch-punch.min.js');
global.Mustache = require('mustache/mustache.min');
require('bpopup/jquery.bpopup.min');
require('select2');

require('../src/turbo_rails_helper');

require('../../../vendor/assets/javascripts/facebox.js');
require('../../../vendor/assets/javascripts/jquery.autocomplete');
require('../../../vendor/assets/javascripts/jquery.cookie');
require('../../../vendor/assets/javascripts/jquery.to_json');
require('../../../vendor/assets/javascripts/json2.min');
require('../../../vendor/assets/javascripts/jquery.sticky');
require('../../../vendor/assets/javascripts/jquery.placeholder.min');
require('../../../vendor/assets/javascripts/datepicker/jscal2');
require('../../../vendor/assets/javascripts/datepicker/en');
require('../../../vendor/assets/javascripts/datepicker/unicode-letter');

require("../src/user_agent_timezone");
require('../src/array_serialization');
require('../src/sticky-sidebars');
require('../src/node-inserted');
require('../src/url_handler');
require('../src/patcher');
require('../src/google_tag_manager_handler');
require('../src/recaptcha_handler');
require('../src/material/common');
require('../src/material/validation');
require('../src/material/form_helper');
require('../src/material/accessibility_handler');
require('../src/material/session');
require('../src/material/file_drop_handler');
require('../src/material/multi_column_select');
require('../src/material/multi_select_dropdown');
require('../src/material/sortable_options');
require('../src/material/graduation_date_handler');
require('../src/material/report');
require('../src/material/pagination');
require('../src/material/table');
require('../src/material/tooltip_handler');
require('../src/material/sidenav.js');
require('../src/popup_decorator');
require('../src/form_handler');
require('../src/application');
require('../src/assistant');
require('../src/training_level_handler');
require('../src/toggle_bar');
require('../src/communication');
require('../src/message_handler');
require('../src/address');
require('../src/preferred_locations');
require('../src/profile');
require('../src/session_helper');
require('../src/facebox_messages');
require('../src/link_decorator');
require('../src/form_helper');
require('../src/home');
require('../src/attachment_viewer');
require('../src/candidate_college');
require('../src/candidate');
require('../src/common_registration');
require('../src/reference_registration_options');
require('../src/address_relations_loader');
require('../src/flash_messages');
require('../src/weights');
require('../src/referral');
require('../src/facebook/auth');
require('../src/select2_handler');
require('../src/facebox_helper');
